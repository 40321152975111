import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import {
  Container,
  StyledHeaderImage,
  ImageWrapper,
  Flex,
  ActionButton,
  HeaderTextGroup,
  Subtitle,
  HeaderWrapper,
} from "../../global"

const NakupKnjigeHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "knjiga" }
        name: { eq: "knjiga-naslovnica" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Nakup knjige</Subtitle>
            <h1>
              Božja ljubezen
              <br />
              odpušča in ozdravlja
            </h1>
            <CartButton>Cena: 22,95 eur</CartButton>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledHeaderImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default NakupKnjigeHeader

const CartButton = styled(ActionButton)`
  background: ${(props) => props.theme.color.accent};
  cursor: initial;
`
