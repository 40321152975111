import React, { Component } from "react"
import styled from "styled-components"
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import {
	SectionTitle,
	Section,
	StyledContainer,
	ActionButton,
} from "../../global"

export default class NakupKnjigeForm extends Component {
	constructor(props) {
		super(props);
		this.state = { kolicina: '1', cena: 26.05, dostava: 3.10};

		this.handleKolicinaChange = this.handleKolicinaChange.bind(this);
	}

	handleKolicinaChange(event) {
		this.setState({ kolicina: event.target.value });
		let cena_full = (Number(event.target.value) * 22.95) + this.state.dostava
		this.setState({ cena: cena_full.toFixed(2) });
	}

	render() {
		return (
			<Section>
				<StyledContainer>
					<SectionTitle>Naroči knjigo</SectionTitle>
					<form method="POST" name='naroci-knjigo' action="/narocilo-oddano" data-netlify="true" data-netlify-honeypot="bot-field">
						<SectionDescription>
							<Accordion preExpanded={"first"}>
								<AccordionItem uuid={"first"}>
									<AccordionItemHeading>
										<AccordionItemButton>
											Naslov za dostavo
                </AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<FormField>
											<FormFieldLabel for="ime-priimek">Ime in Priimek *</FormFieldLabel>
											<FormFieldInput type="text" name="ime-priimek" id="ime-priimek" required='required' />
										</FormField>
										<FormField>
											<FormFieldLabel for="email">E-pošta *</FormFieldLabel>
											<FormFieldInput type="email" name="email" id="email" required='required' />
										</FormField>
										<FormField>
											<FormFieldLabel for="organizacija">Organizacija</FormFieldLabel>
											<FormFieldInput type="text" name="organizacija" id="organizacija" />
										</FormField>
										<FormField>
											<FormFieldLabel for="davcna-st">Davčna št</FormFieldLabel>
											<FormFieldInput type="text" name="davcna-st" id="davcna-st" />
										</FormField>
										<FormField>
											<FormFieldLabel for="davcna-st">Naslov *</FormFieldLabel>
											<FormFieldInput type="text" name="naslov" id="naslov" required />
										</FormField>
										<FormField>
											<FormFieldLabel for="postna-st">Poštna št. *</FormFieldLabel>
											<FormFieldInput type="text" name="postna-st" id="postna-st" required />
										</FormField>
										<FormField>
											<FormFieldLabel for="mesto">Mesto *</FormFieldLabel>
											<FormFieldInput type="text" name="mesto" id="mesto" required />
										</FormField>
										<FormField>
											<FormFieldLabel for="drzava">Država</FormFieldLabel>
											<FormFieldInput type="text" name="drzava" id="drzava" value="Slovenija" disabled />
										</FormField>
										<FormField>
											<FormFieldLabel for="tel">Telefon *</FormFieldLabel>
											<FormFieldInput type="text" name="tel" id="tel" required />
										</FormField>
									</AccordionItemPanel>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											Plačilo in dostava
                </AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<h4>Izberi način dostave</h4>
										<RadioInput type="radio" name="nacin-dostave" id="nacin-dostave" value="Pošta" required='required' checked /><RadioLabel>Dostava po pošti</RadioLabel>
										<h4>Izberi način plačila</h4>
										<RadioInput type="radio" name="nacin-placila" id="nacin-placila-povzetje" value="Po povzetju" checked /><RadioLabel>Plačilo po povzetju</RadioLabel> <br />
										<RadioDescription>Plačilo z gotovino/preko TRR-ja po povzetju.</RadioDescription>
										<RadioInput type="radio" name="nacin-placila" id="nacin-placila-povzetje" value="Po predračunu" checked /><RadioLabel>Plačilo po predračunu</RadioLabel> <br />
                                        <RadioDescription>Plačilo po predračunu.</RadioDescription>
									</AccordionItemPanel>
								</AccordionItem>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											Potrditev
                </AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<h4>Preglejte in potrdite naročilo</h4>
										<Table >
											<TableRow>
												<th>Količina</th>
												<th>Artikel</th>
												<th>Cena</th>
											</TableRow>
											<TableRow>
												<td><FormFieldInput type="text" name="kolicina" id="kolicina" required='required' value={this.state.kolicina} placeholder="Vnesite željeno količino" onChange={this.handleKolicinaChange} /></td>
												<td>Knjiga: Božja ljubezen odpušča in ozdravlja</td>
												<td>22.95 EUR</td>
											</TableRow>
											<TableRow>
												<td></td>
												<td>Dostava: Dostava po pošti</td>
												<td>{this.state.dostava} EUR</td>
											</TableRow>
											<TableRow>
												<td></td>
												<td>Znesek za plačilo</td>
												<td>{this.state.cena} EUR</td>
											</TableRow>
										</Table>
										<Policy>
											Z oddajo naročila se strinjate z vsemi pogoji poslovanja in se zavezujete k plačilu (naročilo z obveznostjo plačila).
										</Policy>
										<ActionButton type="submit">Naroči</ActionButton>
									</AccordionItemPanel>
								</AccordionItem>
							</Accordion>
						</SectionDescription>
						<input type="hidden" name="bot-field" />
						<input type="hidden" name="koncna-cena" id="koncna-cena" value={this.state.cena}/>
						<input type="hidden" name="form-name" value="naroci-knjigo" />
					</form>
				</StyledContainer>
			</Section>
		)
	}
}

const SectionDescription = styled.div`
`

const RadioLabel = styled.span`
	height: 3rem;
	border: none;
	background-color: #f9f9f9;
	padding: 1rem;
	border-radius: 0.2rem;
	width: 100%;
	box-shadow: 0px 2px 0px 0px #eee;
	display: block;
	padding-left: 4rem;
	font-family: ${props => props.theme.font.normal};
`

const RadioInput = styled.input`
	visibility: hidden;

	&:after {
		margin-top: 1.7rem;
		position: absolute;
		display: block;
		height: 3rem;
		cursor: pointer;
		margin-left: 0.5rem;
  	height: 25px;
  	width: 25px;
  	background-color: #bbb;
  	border-radius: 50%;
		content: '';
		visibility: visible;
		border: 2px solid white;
	}

	&:checked:after {
		background-color: ${props => props.theme.color.accent};
	}
`

const RadioDescription = styled.div`
	padding-left: 2rem;
	padding-right: 2rem;
	text-align: center;
	margin-bottom: 4rem;
	font-style: italic;
	opacity: 0.5;
`

const FormField = styled.div`
	margin: 1rem;
`

const FormFieldLabel = styled.label`
	display: block;
	margin-bottom: 0.5rem;
	font-family: ${props => props.theme.font.normal};
`

const FormFieldInput = styled.input`
	height: 3rem;
	border: none;
	background-color: #f9f9f9;
	padding: 1rem;
	border-radius: 0.2rem;
	width: 100%;
  box-shadow: 0px 2px 0px 0px #eee;
`
const Table = styled.table`
	width: 100%;
`

const TableRow = styled.tr`
	text-align: center;
	font-family: ${props => props.theme.font.bold};
`

const Policy = styled.p`
	padding-top: 2rem;
	padding-bottom: 2rem;
	display: block;
	font-style: italic;
`
