import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import NakupKnjigeHeader from "../components/sections/nakup-knjige/nakup-knjige-header"
import NakupKnjigeForm from "../components/sections/nakup-knjige/nakup-knjige-form"
import Footer from "../components/common/footer"

const Knjiga = () => (
  <Layout>
    <SEO title="Nakup knjige: Božja " />
    <NakupKnjigeHeader />
    <Navigation />
    <NakupKnjigeForm />
    <Footer />
  </Layout>
)

export default Knjiga
